.table-leads {
    thead {
        background-color: #186cd1;

        tr {
            th {
                color: white;
                vertical-align: middle;
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            th {
                vertical-align: middle;
                text-align: center;

                .badge {
                    width: 100%;
                }
            }
        }
    }

}